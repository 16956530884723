import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { catchError, debounceTime, distinctUntilChanged, finalize, of, switchMap, takeUntil } from 'rxjs'

import { UserProfileComponent } from '../user-profile.component'
import { ActivationStatus } from '../../../../core/enums/user/activation-status'
import { Popup } from '../../../../core/classes/global/popup'
import { UserSearchResponse } from '../../../../core/interfaces/user/user-search-response'
import { SnackbarService } from '../../../components/snackbar/snackbar.service'
import { BaseComponent } from '../../../components/base/base.component'
import { OrgService, UsersService } from '../../../../core/api'
import { DashboardState } from '../../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../../core/interfaces/organization/organization'

@Component({
  selector: 'app-relations',
  templateUrl: 'relations.component.html',
  styleUrl: 'relations.component.scss',
  standalone: false
})
export class RelationsComponent extends BaseComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input({ required: true }) hostComponent: UserProfileComponent

  @Output() openProfile: EventEmitter<number> = new EventEmitter()
  @Output() guardianAdded: EventEmitter<void> = new EventEmitter()

  addGuardianPopup: Popup = new Popup()
  isSearchingByName: boolean
  searchByNameControl: FormControl<string> = new FormControl('', { nonNullable: true })
  searchByEmailControl: FormControl<string> = new FormControl('', { nonNullable: true })
  searchByNameResults: UserSearchResponse[]
  searchByEmailResults: UserSearchResponse[]
  selectedUserByName: UserSearchResponse | null = null
  selectedUserByEmail: UserSearchResponse | null = null
  addGuardianSearchLoading: boolean = false
  userList: UserSearchResponse[]
  noUserFoundByName: boolean = false
  noUserFoundByEmail: boolean = false

  get ActivationStatus() {
    return ActivationStatus
  }

  constructor(
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private usersService: UsersService,
    private orgService: OrgService
  ) {
    super()
  }

  ngOnInit() {
    this.initSearchGuardianByName()
    this.initSearchGuardianByEmail()
  }

  setSearchingByName(isSearchingByName: boolean): void {
    this.isSearchingByName = isSearchingByName
    this.userList = isSearchingByName ? this.searchByNameResults : this.searchByEmailResults
  }

  openAddGuardianPopup() {
    this.addGuardianPopup.open()

    this.searchByNameResults = []
    this.searchByEmailResults = []

    this.setSearchingByName(true)
  }

  closeAddGuardianPopup(): void {
    this.addGuardianPopup.close()
    this.searchByNameControl.reset()
    this.searchByEmailControl.reset()
  }

  selectUser(user: UserSearchResponse): void {
    if (this.isSearchingByName) {
      this.selectedUserByName = user
    } else {
      this.selectedUserByEmail = user
    }
  }

  addGuardian(): void {
    const userToAdd = this.isSearchingByName ? this.selectedUserByName : this.selectedUserByEmail
    const isExistingGuardian = !!this.hostComponent.user!.guardians.find(
      (existingGuardian) => existingGuardian.id === userToAdd!.id
    )
    if (isExistingGuardian) {
      this.snackbarService.error(
        `${userToAdd!.first_name} ${this.translate.instant('toaster.already_registered')} ${this.hostComponent.user!.first_name}`
      )
      return
    }

    this.addGuardianPopup.showLoader()
    this.usersService
      .addGuardian(this.hostComponent.user!.id, userToAdd!.id)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => this.addGuardianPopup.hideLoader())
      )
      .subscribe(() => {
        this.addGuardianPopup.close()
        this.guardianAdded.emit()
        this.snackbarService.success(
          `${userToAdd!.first_name} ${this.translate.instant('toaster.is_provided')} ${this.hostComponent.user!.first_name}`
        )
      })
  }

  private initSearchGuardianByName(): void {
    this.searchByNameControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUserByName = null
          const searchQuery = value.trim()
          if (searchQuery) {
            this.addGuardianSearchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { fullNameQuery: searchQuery })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response) => {
        this.addGuardianSearchLoading = false
        this.searchByNameResults = response
        this.userList = response
        this.noUserFoundByName = !!this.searchByNameControl.value.trim() && !response.length
      })
  }

  private initSearchGuardianByEmail(): void {
    this.searchByEmailControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUserByEmail = null
          if (value) {
            this.addGuardianSearchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { emailQuery: value })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response) => {
        this.addGuardianSearchLoading = false
        this.searchByEmailResults = response
        this.userList = response
        this.noUserFoundByEmail = !!this.searchByEmailControl.value.trim() && !response.length
      })
  }

  openUserProfile(userId: number): void {
    this.openProfile.emit(userId)
  }
}
