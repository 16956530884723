<div class="container">
  <div class="profile-picture-wrapper">
    <app-profile-picture [viewable]="true" [user]="hostComponent.user!"></app-profile-picture>
    <div>
      <h6>{{ hostComponent.user!.first_name }} {{ hostComponent.user!.last_name }}</h6>

      @if (
        hostComponent.user!.activation_status === ActivationStatus.Invited && !hostComponent.user!.supervised_by_parents
      ) {
        <p class="receives-emails ns-test extra-small">
          {{ 'user_attributes.receives_notifications_via_email' | translate }}
        </p>
      } @else if (hostComponent.user!.last_seen) {
        <p class="online-status ns-test extra-small">
          {{ 'user_attributes.last_online' | translate }}
          {{ hostComponent.user!.last_seen | date: DateFormat.shortDate2 }}
        </p>
      }

      <div class="activation-status-wrapper">
        <app-user-activation-status
          [status]="hostComponent.user!.activation_status"
          [supervised]="hostComponent.user!.supervised_by_parents"
          [disableTooltip]="true"
        ></app-user-activation-status>
        @switch (hostComponent.user!.activation_status) {
          @case (ActivationStatus.Active) {
            <p class="ns-text small">{{ 'user_activation_status.active' | translate }}</p>
          }

          @case (ActivationStatus.Child) {
            <p class="ns-text small">{{ 'user_activation_status.supervised' | translate }}</p>
          }

          @case (ActivationStatus.Invited) {
            @if (hostComponent.user!.supervised_by_parents) {
              <p class="ns-text small">{{ 'user_activation_status.supervised' | translate }}</p>
            } @else {
              <p class="ns-text small">{{ 'user_activation_status.not_completed_registration' | translate }}</p>
            }
          }

          @case (ActivationStatus.WaitingForMembership) {
            <p class="ns-text small">{{ 'user_activation_status.awaits_membership' | translate }}</p>
          }
        }
      </div>
    </div>
  </div>

  <p class="sub-header ns-subtitle">{{ 'user_profile.main_information' | translate }}</p>

  <p class="label ns-text small">{{ 'user_attributes.phone_number' | translate }}</p>
  <p class="ns-text">
    {{
      hostComponent.user!.phone_mobile
        ? (hostComponent.user!.phone_mobile | phone)
        : ('user_profile.mobile_number_not_provided' | translate)
    }}
  </p>

  <p class="label ns-text small">{{ 'user_attributes.email' | translate }}</p>
  <p class="ns-text">
    @if (hostComponent.user!.email) {
      <a [attr.href]="'mailto:' + hostComponent.user!.email" class="user-email"> {{ hostComponent.user!.email }} </a>
    } @else {
      {{ 'user_profile.email_not_provided' | translate }}
    }
  </p>

  <p class="label ns-text small">{{ 'user_attributes.birth_date' | translate }}</p>
  <p class="ns-text">
    @if (hostComponent.user!.date_of_birth) {
      {{ hostComponent.user!.date_of_birth | date: DateFormat.shortDate2 }}
    } @else {
      {{ 'user_profile.dob_not_provided' | translate }}
    }
  </p>

  <p class="label ns-text small">{{ 'user_attributes.gender' | translate }}</p>
  <p class="ns-text">
    @switch (hostComponent.user!.gender) {
      @case (Gender.Male) {
        {{ 'gender.male' | translate }}
      }
      @case (Gender.Female) {
        {{ 'gender.female' | translate }}
      }
      @default {
        {{ 'user_profile.gender_not_provided' | translate }}
      }
    }
  </p>

  @if (hostComponent.isCurrentUser) {
    <p class="label ns-text small">{{ 'user_attributes.nationality' | translate }}</p>
    <p class="ns-text">{{ hostComponent.user!.nationality.name }}</p>
  }

  <p class="label ns-text small">{{ 'user_attributes.address' | translate }}</p>
  @if (!hostComponent.user!.street_address && !hostComponent.user!.postal_code && !hostComponent.user!.city) {
    <p class="ns-text">{{ 'user_profile.address_not_provided' | translate }}</p>
  } @else {
    <p class="ns-text">
      {{ hostComponent.user!.street_address
      }}<span *ngIf="hostComponent.user!.street_address && hostComponent.user!.postal_code">, </span>
      {{ hostComponent.user!.postal_code }} {{ hostComponent.user!.city }}
    </p>
  }

  @if (hostComponent.user!.media_privacy_permission) {
    <p class="label ns-text small">{{ 'user_attributes.photo_permission' | translate }}</p>
    @if (hostComponent.isCurrentUser) {
      @switch (hostComponent.user!.media_privacy_permission) {
        @case (MediaPrivacyPermission.Internal) {
          <p class="ns-text">{{ 'user_attributes.permission_internal_text' | translate }}</p>
        }
        @case (MediaPrivacyPermission.Public) {
          <p class="ns-text">{{ 'user_attributes.permission_public_text' | translate }}</p>
        }
        @case (MediaPrivacyPermission.NoPermission) {
          <p class="ns-text">{{ 'user_attributes.no_permission' | translate }}</p>
        }
        @case (MediaPrivacyPermission.NotSet) {
          <p class="ns-text">
            {{ 'user_attributes.permission_internal_text' | translate }}
            ({{ 'organization.not_set' | translate | lowercase }})
          </p>
        }
      }
    } @else {
      @switch (hostComponent.user!.media_privacy_permission) {
        @case (MediaPrivacyPermission.Internal) {
          <p class="ns-text">{{ 'user_attributes.permission_internal_label' | translate }}</p>
        }
        @case (MediaPrivacyPermission.Public) {
          <p class="ns-text">{{ 'user_attributes.permission_public_label' | translate }}</p>
        }
        @case (MediaPrivacyPermission.NoPermission) {
          <p class="ns-text">{{ 'user_attributes.no_permission_label' | translate }}</p>
        }
        @case (MediaPrivacyPermission.NotSet) {
          <p class="ns-text">
            {{ 'user_attributes.no_permission_label' | translate }}
            ({{ 'organization.not_set' | translate | lowercase }})
          </p>
        }
      }
    }
  }
</div>

@if (hostComponent.customFields.length) {
  <div class="divider"></div>

  <div class="container">
    <p class="sub-header ns-subtitle">{{ 'user_profile.additional_information' | translate }}</p>

    <ul class="custom-fields">
      @for (iterator of hostComponent.customFieldsGrouped | keyvalue; track $index) {
        <p class="group-name ns-subtitle small">
          {{ iterator.value.groupName }}

          @if (iterator.value.parentGroupName) {
            ({{ iterator.value.parentGroupName }})
          }
        </p>

        @for (field of iterator.value.customFields; track $index) {
          <li>
            <p class="label ns-text small">{{ field.name }}</p>
            <p class="ns-text">{{ field.field_data.value || '-' }}</p>
          </li>
        }
      }
    </ul>
  </div>
}
