import { Component, Input } from '@angular/core'

import { ActivationStatus } from '../../../../core/enums/user/activation-status'
import { DateFormat } from '../../../../core/enums/global/date-format'
import { Gender } from '../../../../core/enums/user/gender'
import { MediaPrivacyPermission } from '../../../../core/enums/user/media-privacy-permission'
import { UserProfileComponent } from '../user-profile.component'

@Component({
  selector: 'app-general-info',
  templateUrl: 'general-info.component.html',
  styleUrl: 'general-info.component.scss',
  standalone: false
})
export class GeneralInfoComponent {
  @Input({ required: true }) hostComponent: UserProfileComponent

  get DateFormat() {
    return DateFormat
  }

  get Gender() {
    return Gender
  }

  get MediaPrivacyPermission() {
    return MediaPrivacyPermission
  }

  get ActivationStatus() {
    return ActivationStatus
  }
}
