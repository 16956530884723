import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'
import { DatePipe, NgTemplateOutlet } from '@angular/common'
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { Store } from '@ngxs/store'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { forkJoin, merge, Observable, of, Subject } from 'rxjs'
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { parsePhoneNumber } from 'libphonenumber-js'
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamChatModule } from 'stream-chat-angular'
import { Channel } from 'stream-chat'
import { MatRippleModule } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatIconButton } from '@angular/material/button'
import { MatInput } from '@angular/material/input'

import { UsersService, OrgService } from '../../../core/api'
import { DateFormat } from '../../../core/enums/global/date-format'
import { JerseyNumberGroup } from './interfaces/jersey-number-group'
import { TabModes } from './enums/tab-modes'
import { UserProfileTab } from './enums/user-profile-tab'
import { Gender } from '../../../core/enums/user/gender'
import { SetUser, SetUserPicture } from '../../../module/dashboard/states/dashboard.actions'
import { DashboardState } from '../../../module/dashboard/states/dashboard.state'
import { SnackbarService } from '../../components/snackbar/snackbar.service'
import { UpdateUserProfile, ToggleUserProfile, UpdateUserMembership } from './state/user-profile.actions'
import { Membership, UserProfileState } from './state/user-profile.state'
import { UserResponse } from '../../../core/interfaces/user/user-response'
import { GroupSelectorState } from '../group-selector/states/group-selector.state'
import { MediaPrivacyPermission } from '../../../core/enums/user/media-privacy-permission'
import { Organization } from '../../../core/interfaces/organization/organization'
import { GroupResponse } from '../../../core/interfaces/organization/group-response'
import { SelectedGroup } from '../../../core/interfaces/organization/selected-group'
import { CurrentUserResponse } from '../../../core/interfaces/user/current-user-response'
import { userProfileAnimations } from './animations/user-profile-animations'
import { CustomFieldResponse } from './interfaces/custom-field-response'
import { FloatComponent } from '../../components/float/float.component'
import { InvitationReminderDataResponse } from './interfaces/invitation-reminder-data-response'
import { ActivationStatus } from '../../../core/enums/user/activation-status'
import { FloatingElementDirective } from '../../directives/floating-element.directive'
import { FloatService } from '../../components/float/float.service'
import { UserProfileModule } from './user-profile.module'
import { ButtonComponent } from '../../components/button/button.component'
import { ProfilePictureComponent } from '../../components'
import { LoaderComponent } from '../loader/loader.component'
import { ExpandXComponent } from '../../components/animations/expand-x.component'
import { ExpandYComponent } from '../../components/animations/expand-y.component'
import { InfoBlockComponent } from '../../components/info-block/info-block.component'
import { ScrollShadowDirective } from '../../directives/scroll-shadow.directive'
import { ModalComponent } from '../modal/modal.component'
import { MembershipResponse } from './memberships/interfaces/membership-response'
import { Modal } from '../../../core/classes/global/modal'
import { GetFullNamePipe } from '../../pipes/get-full-name.pipe'
import { Logout } from '../../states/global.actions'
import { PinnedMessagesComponent } from '../../../module/dashboard/tabs/communication-tab/chat/pinned-messages/pinned-messages.component'

interface UserProfileForm {
  firstName: FormControl<string>
  lastName: FormControl<string>
  email: FormControl<string>
  phoneCountryCode: FormControl<string | null>
  phoneMobile: FormControl<string | null>
  dateOfBirth: FormControl<string | null>
  gender: FormControl<Gender>
  mediaPrivacyPermission: FormControl<MediaPrivacyPermission>
  streetAddress?: FormControl<string>
  postalCode?: FormControl<string>
  city?: FormControl<string>
  customFields?: FormGroup<{
    [fieldId: number]: FormControl<any>
  }>
}

interface ResendInvitationForm {
  email: FormControl<string>
}

// TODO: move out of /shared/modules
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: { '[@userProfileAnimation]': '' },
  hostDirectives: [FloatingElementDirective],
  animations: userProfileAnimations,
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInput,
    MatSelectModule,
    UserProfileModule,
    StreamChatModule,
    MatIcon,
    MatIconButton,
    ProfilePictureComponent,
    LoaderComponent,
    ExpandXComponent,
    ExpandYComponent,
    ButtonComponent,
    ModalComponent,
    InfoBlockComponent,
    PinnedMessagesComponent,
    DatePipe,
    GetFullNamePipe,
    FloatingElementDirective,
    ScrollShadowDirective
  ]
})
export class UserProfileComponent extends FloatComponent implements OnInit, OnDestroy {
  @SelectSnapshot(DashboardState.organization) organization: Organization
  @SelectSnapshot(DashboardState.rootAdminGroups) rootAdminGroups: GroupResponse[]
  @SelectSnapshot(DashboardState.user) currentUser: CurrentUserResponse
  @SelectSnapshot(DashboardState.isTopLevelAdmin) isTopLevelAdmin: boolean
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup
  @SelectSnapshot(UserProfileState.userId) userId: number
  @SelectSnapshot(UserProfileState.tabToOpen) tabToOpen: UserProfileTab
  @SelectSnapshot(UserProfileState.membership) membership: { model: Membership }

  loading: boolean = false
  mobileView: 'menu' | 'details' = 'menu'
  user: UserResponse | null = null
  smsSender: string | null = null
  lastInvitationDatetime: string | null = null
  customFields: CustomFieldResponse[] = []
  customFieldsGrouped: {
    [groupId: number]: {
      groupName: string
      parentGroupName: string | null
      customFields: CustomFieldResponse[]
    }
  } = {}
  areCustomFieldsLoading: boolean = false
  userProfileForm: FormGroup<UserProfileForm>
  resendInvitationForm: FormGroup<ResendInvitationForm>
  activeTab: UserProfileTab = UserProfileTab.Info
  mainTabMode: TabModes = TabModes.Overview
  membershipsTabMode: TabModes = TabModes.Overview
  memberships: MembershipResponse[] | null = null
  selectedMembership: MembershipResponse | null = null
  deleteMembershipModal: Modal = new Modal()
  contactInfoRequired: boolean = false
  selectedJerseyNumberGroup: JerseyNumberGroup
  listOfPreviouslyOpenedUsers: number[] = []
  channels: Channel<DefaultStreamChatGenerics>[]

  private anotherProfileOpened$: Subject<void> = new Subject()
  private componentDestroyed$: Subject<void> = new Subject()

  get isLastAdminMembership(): boolean {
    return (
      this.isCurrentUser &&
      !this.memberships!.filter((membership) => membership !== this.selectedMembership).some(
        (membership) => membership.is_admin
      )
    )
  }

  get isSmallScreen(): boolean {
    return window.innerWidth <= 768
  }

  get isCurrentUser() {
    return this.userId === this.currentUser.id
  }

  get DateFormat() {
    return DateFormat
  }

  get UserProfileTab() {
    return UserProfileTab
  }

  get TabModes() {
    return TabModes
  }

  get ActivationStatus() {
    return ActivationStatus
  }

  constructor(
    public elementRef: ElementRef,
    floatService: FloatService,
    private formBuilder: NonNullableFormBuilder,
    private snackbarService: SnackbarService,
    private usersService: UsersService,
    private orgService: OrgService,
    private translate: TranslateService,
    private store: Store,
    private chatClientService: ChatClientService,
    private channelService: ChannelService
  ) {
    super(elementRef, floatService)
  }

  ngOnInit() {
    this.initUser(this.userId)
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }

  private setActiveTab(tab: UserProfileTab): void {
    this.activeTab = tab
    this.mainTabMode = this.membershipsTabMode = TabModes.Overview
  }

  // Used in the template only
  openTab(tab: UserProfileTab): void {
    if (this.user) {
      this.setActiveTab(tab)
      this.mobileView = 'details'

      // if (this.isSmallScreen) {
      //   history.pushState({ 'profile-details': true }, '')
      // }

      if (tab === UserProfileTab.Chat) {
        this.setChatChannel()
      }
    }
  }

  mobileBack(): void {
    if (this.activeTab === UserProfileTab.Info && this.mainTabMode !== TabModes.Overview) {
      this.mainTabMode = TabModes.Overview
    } else if (this.activeTab === UserProfileTab.Memberships && this.membershipsTabMode !== TabModes.Overview) {
      this.membershipsTabMode = TabModes.Overview
    } else {
      this.mobileView = 'menu'
    }

    // history.back()
  }

  private initResendInvitationForm(): void {
    this.resendInvitationForm = this.formBuilder.group({
      email: [this.user!.email, Validators.email]
    })
  }

  openEditUserDetails(): void {
    this.userProfileForm = this.formBuilder.group({
      firstName: [this.user!.first_name],
      lastName: [this.user!.last_name],
      email: [this.user!.email, Validators.email],
      phoneCountryCode: [
        (this.user!.phone_mobile ? '+' + parsePhoneNumber(this.user!.phone_mobile).countryCallingCode : null) as
          | string
          | null
      ],
      phoneMobile: [
        (this.user!.phone_mobile ? parsePhoneNumber(this.user!.phone_mobile).nationalNumber : null) as string | null
      ],
      dateOfBirth: [{ value: this.user!.date_of_birth, disabled: !this.isTopLevelAdmin }],
      gender: [this.user!.gender],
      mediaPrivacyPermission: [
        this.user!.media_privacy_permission! === MediaPrivacyPermission.NotSet ||
        this.user!.media_privacy_permission! === MediaPrivacyPermission.NoPermission
          ? MediaPrivacyPermission.NoPermission
          : this.user!.media_privacy_permission!
      ]
    })

    if (this.isCurrentUser) {
      this.userProfileForm.addControl('city', new FormControl(this.user!.city, { nonNullable: true }))
      this.userProfileForm.addControl(
        'postalCode',
        new FormControl(this.user!.postal_code, { nonNullable: true, validators: Validators.required })
      )
      this.userProfileForm.addControl(
        'streetAddress',
        new FormControl(this.user!.street_address, { nonNullable: true })
      )

      this.userProfileForm.controls.firstName.setValidators(Validators.required)
      this.userProfileForm.controls.lastName.setValidators(Validators.required)
      this.userProfileForm.controls.dateOfBirth.setValidators(Validators.required)
      this.userProfileForm.controls.gender.setValidators(Validators.required)
    }

    if (this.customFields.length) {
      const customFieldsControls: { [fieldId: number]: FormControl<any> } = {}
      this.customFields.forEach(
        (field) =>
          (customFieldsControls[field.id] = new FormControl(
            {
              value: field.field_data.value,
              disabled: !field.can_edit
            },
            {
              validators: field.is_required
                ? field.type === 'boolean'
                  ? Validators.requiredTrue
                  : Validators.required
                : null
            }
          ))
      )
      this.userProfileForm.addControl('customFields', this.formBuilder.group(customFieldsControls))
    }

    this.contactInfoRequired = !!this.user!.phone_mobile
    this.mainTabMode = TabModes.Edit
  }

  private setChatChannel(): void {
    const channel = this.chatClientService.chatClient.channel('messaging', {
      members: [this.currentUser.uuid, this.user!.profile_picture!.uuid!]
    })

    this.channelService.deselectActiveChannel()
    this.channelService.channels$
      .pipe(
        // This will get us the current channel list (if list is not yet inited, it will wait for init)
        filter((channels) => !!channels),
        take(1)
      )
      .subscribe(async () => {
        try {
          await channel.watch()
        } catch (error) {
          this.snackbarService.error(error.message)
          throw error
        }
        this.channelService.setAsActiveChannel(channel)
      })
  }

  private initUser(userId: number, options?: { updateUser?: boolean }) {
    this.loading = true

    this.getCustomFields().subscribe()
    // TODO: try to combine all API calls into one observable
    this.getUser(userId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (user) => {
          if (this.tabToOpen) {
            this.setActiveTab(this.tabToOpen)
          }

          if (options?.updateUser) {
            this.setActiveTab(UserProfileTab.Info)
          } else {
            this.listOfPreviouslyOpenedUsers.push(user.id)
          }

          this.initResendInvitationForm()

          const apiCallsToMake: Observable<any>[] = []

          if (user.can_send_invitation) {
            const invitationReminderAPICall$ = this.getInvitationReminderData(user.id).pipe(catchError(() => of(null)))
            apiCallsToMake.push(invitationReminderAPICall$)
          }

          // Combine all necessary API calls to only hide the loader after all of them are done
          forkJoin(apiCallsToMake)
            .pipe(
              takeUntil(this.componentDestroyed$),
              finalize(() => (this.loading = false))
            )
            .subscribe()
        },
        error: () => (this.loading = false)
      })
  }

  private getUser(userId: number): Observable<UserResponse> {
    return this.orgService.getUser(this.organization.id, userId).pipe(tap((response) => (this.user = response)))
  }

  private getInvitationReminderData(userId: number): Observable<InvitationReminderDataResponse> {
    return this.orgService.getInvitationReminderData(userId, this.organization.id).pipe(
      tap((response) => {
        this.smsSender = response.sender_name
        this.lastInvitationDatetime = response.last_invitation_sent
      })
    )
  }

  private getCustomFields(): Observable<CustomFieldResponse[]> {
    this.areCustomFieldsLoading = true
    return this.orgService.getCustomFields(this.userId, this.organization.id).pipe(
      finalize(() => (this.areCustomFieldsLoading = false)),
      tap((response) => {
        this.customFields = response
        this.customFieldsGrouped = {}

        this.customFields.forEach((field) => {
          if (this.customFieldsGrouped[field.group.id]) {
            this.customFieldsGrouped[field.group.id].customFields.push(field)
          } else {
            this.customFieldsGrouped[field.group.id] = {
              groupName: field.group.name,
              parentGroupName: field.parent_group?.name || null,
              customFields: [field]
            }
          }
        })
      }),
      takeUntil(merge(this.anotherProfileOpened$, this.componentDestroyed$))
    )
  }

  saveProfile() {
    const payload: Partial<UserResponse> = {
      first_name: this.userProfileForm.value.firstName,
      last_name: this.userProfileForm.value.lastName,
      date_of_birth: this.userProfileForm.value.dateOfBirth,
      email: this.userProfileForm.value.email,
      phone_mobile: this.userProfileForm.value.phoneMobile
        ? this.userProfileForm.value.phoneCountryCode + this.userProfileForm.value.phoneMobile
        : '',
      gender: this.userProfileForm.value.gender
    }

    if (this.userProfileForm.controls.city) {
      payload.city = this.userProfileForm.value.city
    }

    if (this.userProfileForm.controls.streetAddress) {
      payload.street_address = this.userProfileForm.value.streetAddress
    }

    if (this.userProfileForm.controls.postalCode) {
      payload.postal_code = this.userProfileForm.value.postalCode
    }

    if (this.userProfileForm.controls.mediaPrivacyPermission.dirty) {
      payload.media_privacy_permission = this.userProfileForm.value.mediaPrivacyPermission
    }

    const updateUserAPICall$ = this.usersService.updateUser(this.user!.id, payload, this.organization.id)
    const getUserAPICall$ = this.orgService
      .getUser(this.organization.id, this.userId)
      .pipe(tap((response) => (this.user = response)))

    const postAPICalls: Observable<any>[] = [updateUserAPICall$]
    const getAPICalls: Observable<any>[] = [getUserAPICall$]

    if (this.customFields.length) {
      const updateCustomFieldsAPICall$ = this.orgService.updateCustomFields(
        this.user!.id,
        this.organization.id,
        this.customFields.map((field) => ({ id: field.id, value: this.userProfileForm.value.customFields![field.id] }))
      )
      const getCustomFieldsAPICall$ = this.getCustomFields()

      postAPICalls.push(updateCustomFieldsAPICall$)
      getAPICalls.push(getCustomFieldsAPICall$)
    }

    this.loading = true
    forkJoin(postAPICalls)
      .pipe(
        tap(() => {
          this.snackbarService.success(this.translate.instant('toaster.user_saved'))
          this.mainTabMode = TabModes.Overview

          if (this.isCurrentUser) {
            this.usersService.getCurrentUser().subscribe((user) => this.store.dispatch(new SetUser(user)))
          }
        }),
        switchMap(() => forkJoin(getAPICalls)),
        finalize(() => (this.loading = false))
      )
      .subscribe()
  }

  handleGuardianAdded() {
    this.loading = true
    this.orgService
      .getUser(this.organization.id, this.userId)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((user) => (this.user = user))

    this.store.dispatch(new UpdateUserProfile())

    if (this.isCurrentUser) {
      this.usersService
        .getCurrentUser()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((user) => this.store.dispatch(new SetUser(user)))
    }
  }

  resendInvitation(): void {
    const payload: { email?: string } = {}
    if (this.resendInvitationForm.value.email) {
      payload.email = this.resendInvitationForm.value.email
    }

    this.loading = true
    this.orgService
      .sendInvitation(this.user!.id, this.organization.id, payload)
      .pipe(
        switchMap(() => {
          this.snackbarService.success(this.translate.instant('user_profile.invitation_sent'))

          const apiCallsToMake: Observable<any>[] = [this.getInvitationReminderData(this.user!.id)]

          // Email is updated when it's filled and submitted in the invitation tab.
          if (this.resendInvitationForm.controls.email.dirty) {
            apiCallsToMake.push(this.getUser(this.user!.id))
          }

          return forkJoin(apiCallsToMake)
        }),
        takeUntil(this.componentDestroyed$),
        finalize(() => (this.loading = false))
      )
      .subscribe()
  }

  openUserProfile(userId: number, options?: { openPreviousUser: boolean }): void {
    this.store.dispatch(new ToggleUserProfile(true, userId))
    this.anotherProfileOpened$.next()
    this.initUser(userId, { updateUser: true })
    this.mobileView = 'menu'

    if (!options?.openPreviousUser) {
      this.listOfPreviouslyOpenedUsers.push(userId)
    }
  }

  openPreviousProfile(): void {
    this.listOfPreviouslyOpenedUsers.pop()
    this.openUserProfile(this.listOfPreviouslyOpenedUsers[this.listOfPreviouslyOpenedUsers.length - 1], {
      openPreviousUser: true
    })
  }

  updateProfilePicture(url) {
    this.user = { ...this.user!, profile_picture: { ...this.user!.profile_picture, url } }
    if (this.isCurrentUser) {
      this.store.dispatch(new SetUserPicture(url))
    }
  }

  openEditMembership(options: { memberships: MembershipResponse[]; selectedMembership: MembershipResponse }): void {
    this.membershipsTabMode = TabModes.Edit
    this.selectedMembership = options.selectedMembership
    this.memberships = options.memberships
  }

  saveMembership(confirm?: boolean): void {
    if (!confirm && !this.membership.model.isPlayer && !this.membership.model.isAdmin) {
      this.deleteMembershipModal.open()
    } else {
      this.deleteMembershipModal.close()
      this.loading = true
      this.orgService
        .updateMembership(this.selectedMembership!.id, {
          is_player: this.membership.model.isPlayer,
          is_admin: this.membership.model.isAdmin,
          jersey_number: this.membership.model.jerseyNumber,
          roles: this.membership.model.titles.map((title) => title.id)
        })
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          if (this.isLastAdminMembership) {
            this.store.dispatch(new Logout())
          } else {
            this.membershipsTabMode = TabModes.Overview
            this.selectedMembership = null
            this.snackbarService.success(this.translate.instant('toaster.changes_were_saved'))
            this.store.dispatch(new UpdateUserMembership())
          }
        })
    }
  }

  @HostListener('mousedown', ['$event.target'])
  handleMouseDown(target): void {
    if (this.elementRef.nativeElement === target) {
      this.closeProfile()
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyDown(): void {
    if (this.canClose()) {
      // if (this.isSmallScreen && this.mobileView === 'details') {
      //   this.mobileBack()
      // } else {
      this.closeProfile()
      // }
    }
  }

  @HostListener('window:popstate', ['$event'])
  popState(): void {
    if (this.canClose() /* && !this.isNavigationInProgress */) {
      // if (this.isSmallScreen && this.mobileView === 'details') {
      //   this.mobileBack()
      // } else {
      this.closeProfile()
      // }
    }
  }

  closeProfile() {
    this.store.dispatch(new ToggleUserProfile(false))
  }
}
