/* eslint-disable max-classes-per-file */
import { ActivatedRouteSnapshot, Routes, UrlMatchResult, UrlSegment } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { LoginComponent } from './module/login/login.component'
import { BrowserGuard, DashboardAuthGuard, LoginGuard } from './core/guards'
import { DashboardComponent } from './module/dashboard/dashboard.component'
import { NotSupportedBrowserComponent } from './module/not-supported-browser/not-supported-browser.component'
import { NothingFoundComponent } from './module/nothing-found/nothing-found.component'
import { environment } from '../environments/environment'
import { OnboardingApi } from './core/api'

// TODO: remove when all registration links on the backend link to the app.
function groupOnboardingPageMatch(url: UrlSegment[]): UrlMatchResult | null {
  /*
  - only one url segment after the first slash
  */
  if (url.length !== 1) {
    return null // for a failed condition
  } else {
    const urlPath = url[0].path
    const urlArr = urlPath.split('-')

    /*
    - existence of a hyphen
    - part after the hyphen to be a number
    */
    if (urlPath.includes('-') && /^\d+$/.test(urlArr[urlArr.length - 1])) {
      return { consumed: url, posParams: { organizationSerialCode: url[0] } } // when all conditions pass
    } else {
      return null
    }
  }
}

@Injectable()
export class ActivateRedirectGuard {
  constructor() {}

  canActivate(): boolean {
    window.location.href = environment.baseAppURL
    return false
  }

  canActivateChild(): boolean {
    return this.canActivate()
  }
}

@Injectable()
export class GroupRegistrationRedirectGuard {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    window.location.href = `${environment.baseAppURL}/join/${route.params.organizationSerialCode}`
    return false
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route)
  }
}

@Injectable()
export class ManageMembershipRedirectGuard {
  constructor(private onboardingService: OnboardingApi) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.onboardingService.getOrganizationOnboardingCode(route.params.groupId).pipe(
      catchError(() => of(null)),
      map((organizationOnboardingCode) => {
        if (organizationOnboardingCode) {
          window.location.href = `${environment.baseAppURL}/join/${organizationOnboardingCode.onboarding_code}/open/${route.params.groupId}`
        } else {
          window.location.href = `${environment.baseAppURL}`
        }
        return false
      })
    )
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route)
  }
}

class Dummy {}

export const ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'activate/:token', component: Dummy, canActivate: [ActivateRedirectGuard] },
  {
    matcher: groupOnboardingPageMatch,
    component: Dummy,
    canActivate: [GroupRegistrationRedirectGuard]
  },
  {
    path: 'manage-membership/:groupId',
    canActivate: [BrowserGuard, ManageMembershipRedirectGuard],
    component: Dummy
  },
  {
    path: 'login',
    loadComponent: () => import('./module/login/login.component').then((m) => m.LoginComponent),
    canActivate: [BrowserGuard, LoginGuard]
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('./module/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent)
  },
  {
    path: 'invited-organization/:registration_code',
    loadChildren: () =>
      import('./module/register-invited-organization/register-invited-organization.module').then(
        (m) => m.RegisterInvitedOrganizationModule
      )
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [BrowserGuard, DashboardAuthGuard],
    canActivateChild: [BrowserGuard, DashboardAuthGuard],
    children: [
      { path: '', redirectTo: 'membership', pathMatch: 'full' },
      {
        path: 'membership',
        loadChildren: () =>
          import('./module/dashboard/tabs/membership-tab/membership-tab.module').then((m) => m.MembershipTabModule)
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./module/dashboard/tabs/events-tab/events-tab.module').then((m) => m.EventsTabModule)
      },
      {
        path: 'communication',
        loadChildren: () =>
          import('./module/dashboard/tabs/communication-tab/communication-tab.module').then(
            (m) => m.CommunicationTabModule
          )
      },
      {
        path: 'groups',
        loadChildren: () =>
          import('./module/dashboard/tabs/groups-tab/groups-tab.module').then((m) => m.GroupsTabModule)
      },
      {
        path: 'economy',
        loadChildren: () =>
          import('./module/dashboard/tabs/economy-tab/economy-tab.module').then((m) => m.EconomyTabModule)
      },
      {
        path: 'accounting',
        loadChildren: () =>
          import('./module/dashboard/tabs/accounting-tab/accounting-tab.module').then((m) => m.AccountingTabModule)
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('./module/dashboard/tabs/locations-tab/locations-tab.module').then((m) => m.LocationsTabModule)
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./module/dashboard/tabs/organization-tab/organization-tab.module').then(
            (m) => m.OrganizationTabModule
          )
      },
      {
        path: 'fotballdata',
        loadChildren: () =>
          import('./module/dashboard/tabs/fotballdata-tab/fotballdata-tab.module').then((m) => m.FotballdataTabModule)
      }
    ]
  },
  { path: '404', component: NothingFoundComponent },
  { path: 'not-supported-browser', component: NotSupportedBrowserComponent },
  { path: '**', component: NothingFoundComponent }
]
