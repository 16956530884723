import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
  state,
  stagger,
  animateChild,
  sequence
} from '@angular/animations'

// TODO: move to a global file?
// TODO: suppress warnings when https://github.com/angular/angular/pull/49036 is merged
const fadeInOutAnimation = (
  enterDurationSeconds: number,
  leaveDurationSeconds: number,
  enterTimingFunction?: string,
  leaveTimingFunction?: string
) => [
  transition(':enter', [
    // Use display: none to avoid the two elements being inserted in the DOM at the same time visually affect the layout.
    style({ opacity: 0, display: 'none' }),
    // Wait for :leave animation to end.
    sequence([animate(`${leaveDurationSeconds}s`, style({ display: 'none' }))]),
    // Revert display property and animate
    sequence([
      style({ display: '*' }),
      animate(`${enterDurationSeconds}s ${enterTimingFunction || 'ease-in-out'}`, style({ opacity: 1 }))
    ])
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(
      `${leaveDurationSeconds}s ${leaveTimingFunction || enterTimingFunction || 'ease-in-out'}`,
      style({ opacity: 0 })
    )
    // query('@titleAnimation', [animateChild()], { optional: true })
  ])
]

export const userProfileAnimations = [
  // Prevents initial animation
  trigger('blockInitialAnimation', [transition(':enter', [])]),
  // Root elements animation
  trigger('userProfileAnimation', [
    transition(':enter', [
      group([
        style({ opacity: 0 }),
        query('.main-block', style({ opacity: 0, transform: 'translateY(20px)' })),
        animate('0.3s ease-in', style({ opacity: 1 })),
        query('.main-block', animate('0.3s 0.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' })))
      ])
    ]),
    transition(':leave', [
      group([
        animate('0.5s ease-in-out', style({ background: 'transparent' })),
        group([
          query('.main-block', animate('0.4s cubic-bezier(0.72, 0, 0.9, 0.8)', style({ opacity: 0 }))),
          query(
            '.main-block',
            animate('0.4s cubic-bezier(0.2, 0.94, 0.9, 0.8)', style({ transform: 'translateZ(-100px)' }))
          )
        ])
      ])
    ])
  ]),
  // Animates sidebar
  trigger('sidebarAnimation', [
    state('true', style({ width: 0, transform: 'rotateY(45deg)' })),
    state('false', style({ width: '*', transform: 'rotate(0)' })),
    transition(
      'true => false',
      group([animate('0.4s cubic-bezier(0.61, 0, 0.42, 1)'), query('@*', [animateChild()], { optional: true })])
    )
  ]),
  // Animates name on small screens
  trigger('nameMobileAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(-20px)', opacity: 0 }),
      animate('0.3s cubic-bezier(0.21, 0, 0.44, 1.3)', style({ transform: 'translateY(0)', opacity: 1 }))
    ])
  ]),
  // Animates sidebar tabs
  trigger('tabsAnimation', [
    transition(':enter', [
      query('li', style({ transform: 'translateY(20px)', opacity: 0 })),
      query(
        'li',
        stagger(
          50,
          animate('0.3s 0.2s cubic-bezier(0.21, 0, 0.44, 1.3)', style({ transform: 'translateY(0)', opacity: 1 }))
        )
      )
    ])
  ]),
  trigger('tabTitleAnimation', fadeInOutAnimation(0.3, 0.15)),
  trigger('contentAnimation', fadeInOutAnimation(0.3, 0.15))
]
