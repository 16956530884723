<p class="sub-header ns-subtitle">{{ 'group.guardians' | translate }}</p>
<div class="container">
  @if (hostComponent.user!.guardians.length) {
    <ul class="user-list">
      @for (guardian of hostComponent.user!.guardians; track $index) {
        <li>
          <button class="user-button" (click)="openUserProfile(guardian.id)">
            <app-profile-picture [user]="guardian"></app-profile-picture>
            <div class="user-button__text">
              <p class="user-button__name ns-subtitle small">{{ guardian.full_name }}</p>
              <p class="ns-text extra-small">{{ guardian.phone_mobile | phone }}</p>
              <p class="ns-text extra-small">{{ guardian.email }}</p>
            </div>
            <mat-icon class="user-button__icon">chevron_right</mat-icon>
          </button>
        </li>
      }
    </ul>

    @if (hostComponent.user!.activation_status !== ActivationStatus.WaitingForMembership) {
      <div class="add-guardian-button-wrapper">
        <app-button size="small" (click)="openAddGuardianPopup()">
          {{ 'organization.add_guardian' | translate }}
        </app-button>
      </div>
    }
  } @else {
    <div class="no-guardians">
      <mat-icon>supervised_user_circle</mat-icon>
      <p class="ns-subtitle small">{{ 'user_profile.no_guardians' | translate }}</p>
      <p class="ns-text small">
        {{ 'user_profile.no_guardians_description' | translate: { name: hostComponent.user!.first_name + ' ' + hostComponent.user!.last_name } }}
      </p>
      @if (hostComponent.user!.activation_status !== ActivationStatus.WaitingForMembership) {
        <app-button size="small" (click)="openAddGuardianPopup()">
          {{ 'organization.add_guardian' | translate }}
        </app-button>
      }
    </div>
  }
</div>

@if (hostComponent.user!.children.length) {
  <div class="divider"></div>
  <div class="container">
    <p class="sub-header ns-subtitle">{{ 'user_profile.children' | translate }}</p>
    <ul class="user-list">
      @for (child of hostComponent.user!.children; track $index) {
        <li>
          <button class="user-button" (click)="openUserProfile(child.id)">
            <app-profile-picture [user]="child"></app-profile-picture>
            <div class="user-button__text">
              <p class="user-button__name ns-subtitle small">{{ child.full_name }}</p>
              <p class="ns-text extra-small">{{ child.phone_mobile | phone }}</p>
              <p class="ns-text extra-small">{{ child.email }}</p>
            </div>
            <mat-icon class="user-button__icon">chevron_right</mat-icon>
          </button>
        </li>
      }
    </ul>
  </div>
}

@if (addGuardianPopup.isOpen) {
  <app-popup
    [loading]="addGuardianPopup.loading"
    [isDismissible]="!addGuardianPopup.loading"
    class="add-guardian"
    (popupClose)="closeAddGuardianPopup()"
  >
    <h3 class="popup__header">
      <span>{{ 'user_profile.add_existing_guardian' | translate }}</span>
      <div class="popup__header-button-wrapper">
        <div class="popup__header-buttons">
          <button mat-icon-button (click)="closeAddGuardianPopup()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </h3>

    <div class="popup__body">
      <ul class="ns-tabs ns-tabs--simple ns-tabs--align-center">
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(true)"
          >
            {{ 'add_user.add_by_name' | translate }}
          </button>
        </li>
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="!isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(false)"
          >
            {{ 'add_user.add_by_email' | translate }}
          </button>
        </li>
      </ul>

      <form class="ns-form ns-light" autocomplete="off">
        @if (isSearchingByName) {
          <app-expand-y class="ns-expandable-field-wrapper">
            <mat-form-field class="ns-expandable-field ns-no-gap">
              <mat-label>{{ 'add_user.enter_name' | translate }}</mat-label>
              <input matInput [formControl]="searchByNameControl" />
            </mat-form-field>
          </app-expand-y>
        } @else {
          <app-expand-y class="ns-expandable-field-wrapper">
            <mat-form-field class="ns-expandable-field ns-no-gap">
              <mat-label>{{ 'add_user.enter_email' | translate }}</mat-label>
              <input matInput [formControl]="searchByEmailControl" />
            </mat-form-field>
          </app-expand-y>
        }
      </form>

      @if (
        userList.length ||
        addGuardianSearchLoading ||
        (isSearchingByName && noUserFoundByName) ||
        (!isSearchingByName && noUserFoundByEmail)
      ) {
        <app-expand-y class="ns-search-results-expand">
          <ng-container [ngTemplateOutlet]="searchResultsTemplate"></ng-container>
        </app-expand-y>
      }
    </div>

    <div class="popup__button-wrapper">
      <app-button
        size="small"
        [disabled]="
          addGuardianSearchLoading ||
          (isSearchingByName && !selectedUserByName) ||
          (!isSearchingByName && !selectedUserByEmail)
        "
        (click)="addGuardian()"
      >
        {{ 'user_profile.add_guardian' | translate }}
      </app-button>
    </div>
  </app-popup>
}

<ng-template #searchResultsTemplate>
  <div class="ns-search-results-wrapper" [appScrollShadow]="['top', 'bottom']">
    @if (addGuardianSearchLoading) {
      <app-loader [size]="50"></app-loader>
    }

    @if (isSearchingByName) {
      @if (noUserFoundByName) {
        <div class="ns-empty-state">
          <p class="ns-subtitle">{{ 'user_profile.no_user_by_name' | translate }}</p>
          <p class="ns-text small">{{ 'user_profile.try_another_name' | translate }}</p>
        </div>
      }
    } @else {
      @if (noUserFoundByEmail) {
        <div class="ns-empty-state">
          <p class="ns-subtitle">{{ 'user_profile.no_user_by_email' | translate }}</p>
          <p class="ns-text small">{{ 'user_profile.try_another_email' | translate }}</p>
        </div>
      }
    }

    @if (userList.length) {
      <ul class="ns-search-results ns-list">
        @for (user of userList; track $index) {
          <li
            class="ns-list__item"
            [class.ns-list__item--selected]="
              (isSearchingByName && user.id === selectedUserByName?.id) ||
              (!isSearchingByName && user.id === selectedUserByEmail?.id)
            "
          >
            <button
              class="ns-list__button ns-list__button--no-shadow"
              type="button"
              appBlurOnClick
              (click)="selectUser(user)"
            >
              <div class="ns-avatar-wrapper">
                <app-profile-picture [user]="user"></app-profile-picture>
                <app-user-activation-status
                  [status]="user.activation_status"
                  [supervised]="user.supervised_by_parents"
                ></app-user-activation-status>
              </div>
              <div class="ns-user-details">
                <p class="ns-name ns-subtitle small">{{ user.first_name }} {{ user.last_name }}</p>
                @if (user.email) {
                  <p class="ns-text extra-small">{{ 'user_attributes.email' | translate }}: {{ user.email }}</p>
                }
                @if ($any(user).groups?.length) {
                  <p class="ns-text extra-small">
                    @for (group of $any(user).groups; track $index; let last = $last) {
                      <span>{{ group.name }}<span *ngIf="!last">, </span></span>
                    }
                  </p>
                }
              </div>
            </button>
          </li>
        }
      </ul>
    }
  </div>
</ng-template>
