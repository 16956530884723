import { ErrorHandler, Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import * as Sentry from '@sentry/angular-ivy'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { UsersService } from '../api'
import { CurrentUserResponse } from '../interfaces/user/current-user-response'

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private isUserLoggedIn$ = this.usersService.isUserLoggedIn()

  constructor(private usersService: UsersService) {
    this.isUserLoggedIn$
      .pipe(
        switchMap((isLoggedIn) => {
          if (isLoggedIn) {
            return this.usersService.getCurrentUser()
          } else {
            return of(null)
          }
        })
      )
      .subscribe((user: CurrentUserResponse) => {
        if (user == null) {
          Sentry.setUser(null)
        } else {
          Sentry.setUser({ id: `${user.id}`, email: user.email })
        }
      })
  }

  handleError(error: any): void {
    // TODO: Check if it's relevant and move to a separate handler if so.
    // const chunkFailedMessage = /Loading chunk [\d]+ failed/
    // if (chunkFailedMessage.test(error.message)) {
    //   window.location.reload()
    //   return
    // }

    if (error instanceof HttpErrorResponse) {
      // Add more data to http errors.
      Sentry.withScope((scope) => {
        scope.update({
          // Group similar errors together based on request url and status code.
          fingerprint: [error.url!, String(error.status)],
          // Add custom tag for usability.
          tags: { 'api-url': error.url },
          // Pass error object as an extra data.
          extra: { error }
        })

        // Use captureMessage() to set custom title and severity level for API-related issues.
        Sentry.captureMessage(`${error.status} error`, 'warning')
      })
    } else {
      // Other errors will end up in Sentry as is.
      // TODO: remove this ↓ if it proves to have no purpose.
      // console.log("Sentry.captureException(error)");
      // Sentry.captureException(error)
    }
  }
}
