import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import * as Sentry from '@sentry/angular-ivy'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.logToSentry) {
  Sentry.init({
    dsn: 'https://931ab437161e4ff78a02243f72604d83@sentry.io/248914',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      // Fixes wrong filenames in console.
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new Sentry.Replay()
    ],
    environment: environment.name,
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment.production ? 1.0 : 0,
    release: environment.buildVersion,
    // tracePropagationTargets: ['localhost', /^https:\/\/api.staging.hoopit\.io/, /^https:\/\/api.hoopit\.io/],
    beforeSend(event, hint) {
      if (
        // Omit default Sentry error handling in favor of a custom Sentry error report in SentryErrorHandler.
        hint.originalException instanceof HttpErrorResponse ||
        // Fixes https://github.com/getsentry/sentry-javascript/issues/2514.
        hint.originalException === 'Timeout'
      ) {
        return null
      }

      return event
    }
  })
  Sentry.setTag('buildNumber', environment.buildNumber)
}

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error: Error) => console.error(error))

// TODO: Move to directive
const intercomInitScript = document.createElement('script')
intercomInitScript.innerHTML = `(function () {
        const w = window;
        let ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', intercomSettings);
        } else {
          const d = document;
          const i = function () {
            i.c(arguments)
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args)
          };
          w.Intercom = i;

          function l() {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/${environment.intercomAppId}';
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          }

          if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })()`

document.head.appendChild(intercomInitScript)

const hotjarScript = document.createElement('script')
// TODO: Find cleaner way to detect testing or development env
if (window.location.href.includes('testing') || !environment.production) {
  hotjarScript.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2637959,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `
} else {
  hotjarScript.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2727861,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `
}
document.head.appendChild(hotjarScript)

// fix for Redux DevTools
if (!environment.production) {
  ;(Map.prototype as any).toJSON = function () {
    return JSON.parse(JSON.stringify([...this]))
  }
}
